import axios from 'axios'
import { isEmpty } from 'lodash'
import { addDays } from 'date-fns'
import { appToasted } from '../../../utils/vue-toasted/app_toasted.js'
import API from '../../../vue_shared/api'
import helpers from '../../../utils/helpers'
import { updateDocReviewStatus } from '../../admin/store/document_dashboard_model'
import { globalModule } from '../../../store/globalModule'
import NrSentry from '../../../utils/nr_sentry'

export default {
  addDocuments: ({ commit }, documents) => commit('addDocuments', documents),

  addProcessingDocIds: ({ commit }, ids) => commit('addProcessingDocIds', ids),

  changeDocVisibility: (
    { dispatch, state },
    { selectedDocIds, release, prettyId, message, templateIds }
  ) => {
    const docIds = [...selectedDocIds]
    dispatch('addProcessingDocIds', docIds)
    axios('/document_change_visibility', {
      method: 'post',
      data: {
        document_id: docIds,
        release,
        pretty_id: prettyId,
        message,
        template_ids: templateIds
      }
    }).then((response) => {
      if (response.data) {
        dispatch('removeDocsFromState', docIds)
        dispatch('fetchFolders', state.prettyId)
        dispatch('removeProcessingDocIds', docIds)
        dispatch('fetchReviewStateCounts', state.prettyId)
        dispatch('resetReviewState', state.activeReviewState)
      }
    })
  },

  batchDraftRedaction: (
    { dispatch, state },
    { customRegex, regexNames, docIds, redactionReason, folder }
  ) => {
    const batchParams = {
      custom_regex: customRegex,
      regex_names: regexNames,
      document_ids: docIds,
      pretty_id: state.prettyId,
      redaction_reason: redactionReason,
      folder
    }
    dispatch('resetCardSelections')
    dispatch('applyDocumentProcessingLabels', docIds)
    API.batchDraftRedact(batchParams)
      .then((response) => {
        const jobs = response.data.jobs
        if (!jobs) {
          appToasted().error('Draft redactions failed to start.')
          return
        }
        jobs.forEach((job, _index) => {
          job.expiry = addDays(new Date(), 2)
          helpers.setPendingJobInLocalStorage(
            `${state.currentUser.id}${state.prettyId}draftRedaction`,
            job
          )
        })
      })
      .catch(() => {
        appToasted().error(
          'Batch draft redaction search process could not be completed.'
        )
        dispatch('refreshDocumentReviewList')
      })
  },

  updateRedactionLayer: ({ commit }, data) =>
    commit('updateRedactionLayer', data),

  setCheckedDocIds: ({ commit }, ids) => {
    commit('setCheckedDocIds', ids)
  },

  // If docId is already checked remove it, else add it.
  toggleDocChecked: ({ commit, state }, docId) => {
    const updatedCheckedDocIds = state.checkedDocIds.includes(docId)
      ? state.checkedDocIds.filter((id) => id !== docId)
      : [...state.checkedDocIds, docId]

    commit('setCheckedDocIds', updatedCheckedDocIds)
  },

  applyDocumentProcessingLabels: ({ state, commit }, ids) => {
    if (!ids.length) return
    ids.forEach((id) => {
      if (!state.documents[id]) return
      commit('updateDocument', { id, attrs: { processing: true } })
    })
  },

  destroyMarkupLayer: ({ dispatch }, { docId, sessionId }) => {
    API.getMarkupLayers(sessionId).then((result) => {
      const layers = result.data.map((l) => l.layerRecordId)
      layers.map(async (layerId) => {
        return API.deleteMarkupLayer(sessionId, layerId).catch(() => {
          // eslint-disable-next-line no-console
          console.log('document failed in PAS')
        })
      })
    })
    dispatch('removeRedactionLayerFromDB', { docId })
  },

  setupBulkDownload: ({ state, dispatch }, { docIds }) => {
    const payload = {
      request_id: state.prettyId,
      bulk_action: 'download',
      doc_ids: docIds
    }
    return API.bulkDocumentAction(payload).then((resp) => {
      const jobId = resp.data.jobId[0]
      const cookieName = `${state.currentUser.id}${state.prettyId}zipFiles`
      const job = {
        jobId: jobId,
        docIds: docIds,
        jobType: 'bulk_download',
        expiry: addDays(new Date(), 2)
      }
      helpers.setPendingJobInLocalStorage(cookieName, job)
      dispatch('addProcessingDocIds', docIds)
    })
  },

  emptyDocuments: ({ commit }) => commit('emptyDocuments'),

  fetchFolders: async ({ commit, dispatch, state }, requestId) => {
    const { folders } = state
    return axios
      .get('/document_review/folders', { params: { pretty_id: requestId } })
      .then((response) => {
        if (Array.isArray(response.data)) {
          const workingFolders = response.data

          // save any existing subfolder information temporarily
          // (to prevent the subfolder lists from collapsing)
          workingFolders.forEach((workingFolder) => {
            const existingFolder = folders.find(
              (f) => workingFolder.id === f.id
            )
            if (existingFolder) {
              workingFolder.folders = existingFolder.folders
            }
          })

          // make sure our current folder still exists
          if (state.activeFolder[0]) {
            const currentFolder = workingFolders.find(
              (f) => f.name === state.activeFolder[0]
            )
            if (!currentFolder) {
              const previousFolder = state.activeFolder[0]
              appToasted().info('Your folder filter has been reset.', {
                action: {
                  text: 'Read more',
                  onClick: (_, toast) => {
                    toast.text(
                      `The folder you had selected ("${previousFolder}") no longer contains any unreleased documents.`
                    )
                  }
                },
                keepOnHover: true
              })
              commit('setActiveFolder', ['', ''])
            }
          }

          commit('setFolders', workingFolders)

          workingFolders.forEach((workingFolder) => {
            if (workingFolder.folders) {
              // also re-request the information to ensure it is not stale
              dispatch('fetchChildFolders', {
                pretty_id: state.prettyId,
                folder_id: workingFolder.id
              })
            }
          })
        }
      })
      .catch((error) => {
        NrSentry.captureError(error)
        throw error
      })
  },

  fetchChildFolders: ({ commit, dispatch, state }, payload) => {
    axios
      .get('/document_review/folders', { params: payload })
      .then((response) => {
        const folders = response.data
        const folderId = payload.folder_id
        // eslint-disable-next-line no-unused-vars

        const currentFolderId = state.folders.find(
          (f) => f.name === state.activeFolder[0]
        )?.id

        // make sure our current folder still exists
        if (folderId === currentFolderId && state.activeFolder[1]) {
          const currentFolder = folders.find(
            (f) => f.name === state.activeFolder[1]
          )
          if (!currentFolder) {
            const previousSubfolder = `${state.activeFolder[0]}/${state.activeFolder[1]}`
            appToasted().info(
              'You have been redirected to the parent folder.',
              {
                action: {
                  text: 'Read more',
                  onClick: (_, toast) => {
                    toast.text(
                      `The folder filter you had selected ("${previousSubfolder}") no longer contains any unreleased documents.`
                    )
                  }
                },
                keepOnHover: true
              }
            )

            commit('setActiveFolder', [state.activeFolder[0], ''])
            dispatch('refreshDocumentReviewList')
            dispatch('fetchReviewStateCounts', state.prettyId)
          }
        }

        commit('setChildFolders', {
          folderId,
          folders
        })
      })
  },

  fetchFolderOptions: ({ commit }, data) => {
    API.fetchRequestDocumentsFolders(data)
      .then((response) => {
        const { folders } = response.data
        commit('setFolderOptions', folders)
      })
      .catch(() => {
        appToasted().error(
          'An error has occured while retrieving folder options.'
        )
      })
  },

  fetchJobStatuses: ({ dispatch, state }) => {
    const pollBulkJob = setInterval(() => {
      API.jobStatuses({ pretty_id: state.prettyId }).then((response) => {
        const jobs = response.data.jobs
        const processingDocIds = helpers.fetchAllProcessingDocIds(
          state.currentUser.id,
          state.prettyId,
          'rapid_review'
        )
        if (processingDocIds.length === 0) {
          clearInterval(pollBulkJob)
          return
        }
        dispatch('addProcessingDocIds', processingDocIds)
        if (typeof jobs !== 'undefined' && jobs.length) {
          dispatch('updateJobStatuses', jobs)
        }
      })
    }, 5000)
  },

  fetchMessageTemplateOptions: ({ commit }) => {
    axios.get('/message_templates.json').then((response) => {
      commit('setMessageTemplateOptions', response.data)
    })
  },

  fetchRedactionReasons: ({ commit }) => {
    return new Promise((resolve, reject) => {
      API.fetchRedactionReasons().then(({ data: response }) => {
        const redactionReasonSetup = {
          autoApplyDefaultReason: true,
          enableRedactionReasonSelection: true,
          enableFreeformRedactionReasons: true,
          maxLengthFreeformRedactionReasons: 80,
          reasons: [
            { reason: 'Client Privilege' },
            { reason: 'Privacy Information' },
            { reason: 'Redacted' }
          ]
        }
        if (response.data.length > 0) {
          redactionReasonSetup.reasons = []
          response.data.forEach((item) => {
            redactionReasonSetup.reasons.push({ reason: item.name })
          })
        }
        resolve(redactionReasonSetup)
        commit('setRedactionReasons', redactionReasonSetup)
      }, reject)
    })
  },

  fetchRegexes: ({ commit }) => {
    axios.get('/admin/redaction_regexes.json').then(({ data: response }) => {
      commit('setRegexes', response.data)
    })
  },

  fetchReviewStateCounts: ({ commit, state }, prettyId) => {
    axios('/document_review/review_state_counts.json', {
      method: 'get',
      params: {
        pretty_id: prettyId,
        folder: state.activeFolder[0],
        subfolder: state.activeFolder[1]
      }
    }).then((response) => {
      commit('setReviewStateCounts', response.data)
      const totalCounts = response.data.total_counts
      const activeFolder = state.activeFolder.filter((fol) => fol.length !== 0)
      if (!activeFolder.length) {
        commit('setTotalDocCount', totalCounts)
      }
    })
  },

  filterByFolders: ({ dispatch }, folder) => {
    dispatch('resetCardSelections')
    dispatch('resetInfiniteLoadingState')
    dispatch('setActiveFolder', folder)
  },

  async filteredIds(
    {}, // eslint-disable-line no-empty-pattern
    {
      folder,
      ids,
      prettyId,
      emailOnly,
      reviewState,
      subfolder,
      title,
      zipOnly,
      redactableDocsOnly
    }
  ) {
    const docIds = await axios
      .post('/filter_documents', {
        folder_filter: folder,
        ids,
        links: false,
        email_only: emailOnly,
        pretty_id: prettyId,
        redactable_docs_only: redactableDocsOnly,
        review_state: reviewState,
        state: 'staff',
        subfolder,
        title,
        zip_only: zipOnly
      })
      .then((response) => {
        if (response.data.length !== undefined) {
          return response.data
        }
        return []
      })
    return docIds
  },

  setAutoSaveState: ({ commit }, val) => commit('setAutoSaveState', val),

  setExistingMarks: ({ commit }, marks) => commit('setExistingMarks', marks),

  setCurrentUser: ({ commit, dispatch }) => {
    axios({
      method: 'get',
      url: '/document_review/find_current_user.json'
    }).then((response) => {
      commit('setCurrentUser', response.data)
      dispatch('fetchJobStatuses')
    })
  },

  getMarkupLayer: ({ state, dispatch }, layerId) => {
    API.getMarkupLayer(state.viewingSessionId, layerId)
      .then((response) => {
        const updatedAt = response?.data?.data?.updatedAt
        dispatch('updateMarkupLayerUpdatedAtInState', updatedAt)
        dispatch('setExistingMarks', response?.data?.marks)
      })
      .catch((error) => {
        appToasted().error(
          'Failed to get Markup Layer data. Please refresh the page and try again.'
        )
        console.log('Error retrieving redactions', error)
      })
  },

  refreshDocumentReviewList: ({ dispatch, state }) => {
    dispatch('resetInfiniteLoadingState')
    dispatch('getDocumentReviewList', {
      activeReviewState: state.activeReviewState,
      activeFolder: state.activeFolder,
      searchTerm: state.searchTerm,
      infinitePage: state.infinitePage,
      prettyId: state.prettyId,
      size: '50'
    })
  },

  getDocumentReviewList: (
    { dispatch, state },
    {
      activeReviewState,
      activeFolder,
      searchTerm,
      infinitePage,
      prettyId,
      size
    }
  ) => {
    state.getDocumentReviewListCancelToken.cancel('canceled')
    state.getDocumentReviewListCancelToken = axios.CancelToken.source()

    const docs = axios
      .get(`/document_review.json?pretty_id=${prettyId}`, {
        cancelToken: state.getDocumentReviewListCancelToken.token,
        params: {
          review_state: activeReviewState,
          folder: activeFolder[0],
          subfolder: activeFolder[1],
          search_term: searchTerm,
          page: {
            number: infinitePage.toString(),
            size
          }
        }
      })
      .then((response) => {
        const documents = response.data.documents
        if (!isEmpty(documents)) {
          if (infinitePage > 1) {
            dispatch('addDocuments', documents)
          } else {
            dispatch('setDocuments', documents)
          }
          dispatch('incrementInfinitePage')
        } else if (infinitePage === 1) {
          dispatch('setDocuments', [])
        }
        return documents
      })
      .catch((error) => {
        console.log('Error retrieving document(s)...', error)
      })

    return docs
  },

  hideModal: ({ commit }) => commit('setActiveModal', ''),

  incrementInfinitePage: ({ commit }) => commit('incrementInfinitePage'),

  resetInfiniteLoadingState: ({ commit }) => {
    commit('incrementInfiniteId')
    commit('resetInfinitePage')
  },

  jobStarted: ({ commit }) => commit('jobStarted'),

  multipleDocsToBurn: ({ dispatch, state }, payload) => {
    dispatch('resetCardSelections')
    API.burnMultipleDraftRedactions({
      create_redaction_log: payload.createRedactionLog,
      document_ids: payload.documentIds,
      request_id: state.prettyId
    })
      .then((response) => {
        const jobs = response.data.jobs
        if (!jobs) {
          appToasted().error('Finalizing document failed to start.')
          return
        }
        const pendingJobs = helpers.getPendingJobsInLocalStorage(
          `${state.currentUser.id}${state.prettyId}burnDraftRedaction`
        )
        jobs.forEach((job, index) => {
          job.expiry = addDays(new Date(), 2)
          helpers.setPendingJobInLocalStorage(
            `${state.currentUser.id}${state.prettyId}burnDraftRedaction`,
            job
          )
          if (jobs.length - 1 === index) {
            dispatch('addProcessingDocIds', payload.documentIds)
            if (isEmpty(pendingJobs)) {
              dispatch('fetchJobStatuses')
              dispatch('fetchReviewStateCounts', state.prettyId)
            }
          }
        })
      })
      .catch((error) => {
        console.log('Error burning multiple draft document(s)...', error)
      })
  },

  emailExtractFiles: ({ dispatch, state }, { docIds }) => {
    if (docIds.length === 0) {
      dispatch('setActiveModal', 'noDocs')
    } else {
      docIds.forEach((docId, _index) => {
        const promise = API.extraction(docId)
        Promise.resolve(promise).then((response) => {
          const jobId = response.data.jobId
          if (!jobId) {
            appToasted().error('Email extraction failed to start.')
            return
          }
          helpers.setPendingJobInLocalStorage(
            `${state.currentUser.id}${state.prettyId}email`,
            {
              docIds: [docId],
              jobId: jobId,
              expiry: addDays(new Date(), 2)
            }
          )
          dispatch('applyDocumentProcessingLabels', [docId])
        })
      })
    }
  },

  ocrDocument: ({ dispatch, state }, { id }) => {
    const docIds = [id]
    return new Promise((resolve, reject) => {
      API.ocrDocument(id)
        .then((response) => {
          const jobId = response.data.job_id
          dispatch('addProcessingDocIds', docIds)
          helpers.setPendingJobInLocalStorage(
            `${state.currentUser.id}${state.prettyId}ocr`,
            {
              docIds,
              jobId,
              expiry: addDays(new Date(), 2)
            }
          )
          dispatch('fetchJobStatuses')
          dispatch('setActiveDoc', null)
          dispatch('setActiveDocId', null)
          resolve()
        })
        .catch(reject)
    })
  },

  flattenDocuments: ({ dispatch, state }, { ids }) => {
    const docIds = [...ids]
    const payload = {
      doc_ids: docIds,
      request_id: state.prettyId,
      bulk_action: 'flatten'
    }
    API.bulkDocumentAction(payload)
      .then((response) => {
        const jobId = response.data.jobId
        if (!jobId) {
          appToasted().error('Document flattening failed to start.')
          return
        }
        if (response.status === 200) {
          dispatch('addProcessingDocIds', docIds)
          helpers.setPendingJobInLocalStorage(
            `${state.currentUser.id}${state.prettyId}bulkFlatten`,
            {
              docIds,
              jobId,
              expiry: addDays(new Date(), 2)
            }
          )
          dispatch('fetchJobStatuses')
          appToasted().success(
            `Flattening ${docIds.length}
              ${docIds.length === 1 ? 'document' : 'documents'}...`
          )
        }
      })
      .catch((error) => {
        appToasted().error('Cannot flatten documents, an error occured.')
        console.log('Error flattening document(s)...', error)
        dispatch('removeProcessingDocIds', docIds)
      })
  },

  removeDocuments: ({ dispatch, state }, { ids }) => {
    const docIds = [...ids]
    const payload = {
      doc_ids: docIds,
      request_id: state.prettyId,
      bulk_action: 'archive'
    }
    API.bulkDocumentAction(payload)
      .then((response) => {
        const jobId = response.data.jobId
        if (!jobId) {
          appToasted().error('Document deletion failed to start.')
          return
        }
        if (response.status === 200) {
          dispatch('applyDocumentProcessingLabels', docIds)
          appToasted().success(
            `Deleting ${docIds.length}
              ${docIds.length === 1 ? 'document' : 'documents'}...`
          )
        }
      })
      .catch((error) => {
        appToasted().error('Cannot delete documents, an error occurred.')
        console.log('Error deleting document(s)...', error)
      })
  },

  removeDocsFromState: ({ commit }, ids) => {
    ids.forEach((id) => {
      commit('removeDocument', id)
    })
  },

  removeProcessingDocIds: ({ commit }, ids) =>
    commit('removeProcessingDocIds', ids),

  removeRedactionLayerFromDB: (_, { docId }) => {
    API.deleteRedactionLayer(docId).catch(() => {
      console.log('document failed to delete in postgres')
      appToasted().error('Oops. Draft redaction could not be deleted.')
    })
  },

  resetCardSelections: ({ commit, dispatch }) => {
    dispatch('setActiveDocId', '')
    commit('setCheckedDocIds', [])
    dispatch('setActiveCheckAll', false)
  },

  resetVisibleState: ({ commit, state, dispatch }) => {
    dispatch('setActiveDocId', '')
    dispatch('setAutoSaveState', '')
    commit('resetDocuments')

    dispatch('fetchFolders', state.prettyId)
    dispatch('fetchReviewStateCounts', state.prettyId)
    dispatch('resetInfiniteLoadingState')
  },

  resetDocuments: ({ commit }) => commit('resetDocuments'),

  resetPageData: ({ commit }) => commit('resetPageData'),

  resetReviewState: ({ dispatch, state }, val) => {
    dispatch('resetCardSelections')
    dispatch('setSearchTerm', '')
    dispatch('setActiveReviewState', val)
    dispatch('fetchReviewStateCounts', state.prettyId)
    dispatch('resetInfiniteLoadingState')
  },

  resetMarkupLayerInfo: ({ commit }) => {
    commit('setRedactionsLayerId', '')
    commit('setViewingSessionId', '')
    commit('setRedactionsLayerCount', 0)
    commit('setRedactionComment', false)
    commit('setExistingMarks', [])
    commit('setAutoSaveState', '')
  },

  setRedactionsLayerId: ({ commit }, val) =>
    commit('setRedactionsLayerId', val),

  setRedactionsLayerCount: ({ commit }, val) =>
    commit('setRedactionsLayerCount', val),

  setActiveDoc: ({ commit }, val) => commit('setActiveDoc', val),

  setActiveDocId: ({ commit }, id) => commit('setActiveDocId', id),

  setActiveFolder: ({ commit }, val) => commit('setActiveFolder', val),

  setActiveModal: ({ commit }, val) => commit('setActiveModal', val),

  setActiveReviewState: ({ commit }, val) => {
    commit('setActiveReviewState', val)
  },

  setActiveCheckAll: ({ commit }, val) => commit('setActiveCheckAll', val),

  setConfirmationDialogState: ({ commit }, val) =>
    commit('setConfirmationDialogState', val),

  setDocument: ({ commit }, val) => commit('setDocument', val),

  setDraftRedactionData: ({ commit }, val) =>
    commit('setDraftRedactionData', val),

  setPrettyId: ({ commit, state }, val) => {
    if (val !== state.prettyId) {
      commit('reinitializeState')
    }

    commit('setPrettyId', val)
  },

  setPageData: ({ commit }, val) => commit('setPageData', val),

  setRedactionComment: ({ commit }, val) => commit('setRedactionComment', val),

  setReviewStateDropdownState: ({ commit }, val) =>
    commit('setReviewStateDropdownState', val),

  setSearchTerm: ({ commit }, val) => commit('setSearchTerm', val),

  setSearchProcessIds: ({ commit }, val) => commit('setSearchProcessIds', val),

  setViewingSessionId: ({ commit }, val) => commit('setViewingSessionId', val),

  uncheckDoc: ({ commit }, { id }) => commit('uncheckDoc', id),

  unzipFiles: ({ dispatch, _state }, { docIds }) => {
    if (docIds.length === 0) {
      dispatch('setActiveModal', 'noDocs')
    } else {
      docIds.forEach((docId, _index) => {
        const promise = API.unzipFiles(docId)
        Promise.resolve(promise).then((response) => {
          const jobId = response.data.jobId
          if (jobId) {
            dispatch('applyDocumentProcessingLabels', [docId])
          } else {
            appToasted().error('Zip file extraction failed to start.')
          }
        })
      })
    }
  },

  async updateBatchStatus({ dispatch, state }, { docIds, reviewState }) {
    dispatch('addProcessingDocIds', docIds)
    return API.batchStatusUpdate({
      docIds,
      reviewState,
      prettyId: state.prettyId
    })
      .then((response) => {
        if (response.data.doc_ids) {
          dispatch('removeProcessingDocIds', docIds)
          dispatch('fetchReviewStateCounts', state.prettyId)
          dispatch('resetReviewState', state.activeReviewState)
        }
        return response.data.doc_ids || []
      })
      .catch(() => {
        appToasted().error(`Unable to move documents to ${reviewState}`)
        dispatch('removeProcessingDocIds', docIds)
      })
  },

  updateDocument: ({ dispatch, state }, { id, doc }) => {
    axios(`/documents/${id}.json`, {
      method: 'put',
      data: doc
    }).then((response) => {
      if (response.data) {
        dispatch('setDocument', response.data)
        dispatch('fetchReviewStateCounts', state.prettyId)
      }
    })
  },

  updateFolder: ({ dispatch, state }, { ids, folder, prettyId }) => {
    dispatch('applyDocumentProcessingLabels', ids)
    const docIds = [...ids]
    axios('/document_review/batch_folder_update', {
      method: 'put',
      data: {
        ids: docIds,
        folder,
        pretty_id: prettyId
      }
    })
      .then((response) => {
        const jobId = response.data.jobId
        if (!jobId) {
          appToasted().error(
            `Moving ${docIds.length} document(s) to folder has failed to start.`
          )
          return
        }
        helpers.setPendingJobInLocalStorage(
          `${state.currentUser.id}${state.prettyId}moveToFolder`,
          {
            docIds,
            jobId,
            activeSelectAll: state.activeCheckAll,
            expiry: addDays(new Date(), 2)
          }
        )
        dispatch('resetCardSelections')
      })
      .catch((_err) => {
        appToasted().error(
          `An error has occured while moving ${docIds.length} document(s) into folder.`
        )
      })
  },

  updateJobStatuses: ({ dispatch, state }, jobs) => {
    const jobTypes = [
      'multiple_doc_burner',
      'draft_redaction',
      'mail_reader',
      'zipfile_reader',
      'zipfile_creator',
      'process_folders',
      'delete_documents',
      'ocr',
      'flatten',
      'redaction_log_service',
      'bulk_doc_download'
    ]

    const cookieNames = {
      multiple_doc_burner: `${state.currentUser.id}${state.prettyId}burnDraftRedaction`,
      draft_redaction: `${state.currentUser.id}${state.prettyId}draftRedaction`,
      mail_reader: `${state.currentUser.id}${state.prettyId}email`,
      zipfile_reader: `${state.currentUser.id}${state.prettyId}zipFileReader`,
      zipfile_creator: `${state.currentUser.id}${state.prettyId}zipFiles`,
      bulk_doc_download: `${state.currentUser.id}${state.prettyId}bulkDocDownload`,
      process_folders: `${state.currentUser.id}${state.prettyId}moveToFolder`,
      delete_documents: `${state.currentUser.id}${state.prettyId}bulkDelete`,
      flatten: `${state.currentUser.id}${state.prettyId}bulkFlatten`,
      ocr: `${state.currentUser.id}${state.prettyId}ocr`,
      redaction_log_service: `${state.currentUser.id}${state.prettyId}redactionLogService`
    }

    const successes = {}
    const failures = {}

    jobTypes.forEach((jobType) => {
      successes[jobType] = 0
      failures[jobType] = 0
    })

    jobs.forEach((job) => {
      if (helpers.backgroundJobFailed(job.status)) {
        const processedJob = helpers.getPendingJob(
          cookieNames[job.job_type],
          job.id
        )
        if (!processedJob) {
          return
        }
        failures[job.job_type] += processedJob.docIds.length
        dispatch('onJobFail', {
          job,
          processedJob,
          cookieName: cookieNames[job.job_type]
        })
      } else if (helpers.backgroundJobComplete(job.status)) {
        const processedJob = helpers.getPendingJob(
          cookieNames[job.job_type],
          job.id
        )
        if (!processedJob) {
          return
        }
        successes[job.job_type] += processedJob.docIds.length
        dispatch('onJobComplete', {
          job,
          processedJob,
          cookieName: cookieNames[job.job_type]
        })
      }
    })

    jobTypes.forEach((jobType) => {
      if (failures[jobType] > 0) {
        const failToastMessages = {
          multiple_doc_burner: `${failures[jobType]} document(s) failed to finalize.`,
          draft_redaction: `Draft redactions failed for ${failures[jobType]} document(s).`,
          mail_reader: `There was an error extracting ${failures[jobType]} email file(s).`,
          zipfile_creator: `There was an error zipping ${failures[jobType]} document(s).`,
          bulk_doc_download: `There was an error downloading ${failures[jobType]} document(s).`,
          zipfile_reader: `There was an error extracting ${failures[jobType]} zip file(s).`,
          process_folders: `There was an error moving ${failures[jobType]} document(s) into folder(s).`,
          delete_documents: `There was an error deleting ${failures[jobType]} document(s).`,
          ocr: `There was an error ocring ${failures[jobType]} document(s).`,
          flatten: `There was an error flattening ${failures[jobType]} document(s).`,
          redaction_log_service: `There was an error appending reaction log to ${failures[jobType]} document(s).`
        }
        appToasted().error(failToastMessages[jobType])
      }
      if (successes[jobType] > 0) {
        const completeToastMessages = {
          multiple_doc_burner: `${successes[jobType]} document(s) finalized and moved to the Redacted stage.`,
          draft_redaction: `Draft redactions complete for ${successes[jobType]} document(s).`,
          mail_reader: `Extraction complete for ${successes[jobType]} email file(s).`,
          zipfile_creator: `Zip processing complete for ${successes[jobType]} document(s).`,
          bulk_doc_download: `Downloading complete for ${successes[jobType]} document(s).`,
          zipfile_reader: `Extraction complete for ${successes[jobType]} zip file(s).`,
          process_folders: `Moved ${successes[jobType]} document(s) into folder(s).`,
          delete_documents: `Deleted ${successes[jobType]} document(s).`,
          ocr: `OCR process completed for ${successes[jobType]} document(s).`,
          flatten: `Flattened ${successes[jobType]} document(s).`,
          redaction_log_service: `${successes[jobType]} document(s) finalized and moved to the Redacted stage.`
        }
        appToasted().success(completeToastMessages[jobType])
      }
    })
  },

  updateComment: ({ commit }, redactionsLayerData) => {
    commit('setRedactionComment', redactionsLayerData.comment)
    commit('updateRedactionLayer', redactionsLayerData)
  },

  updateMarkupLayer: (
    { state, dispatch, commit },
    { updatedAt, layerId, userId, currentMarkupLayer, markData }
  ) => {
    const updatedAtTimestamp = updatedAt
    const redactionsLayerData = helpers.dbParamsObj(
      state.activeDocId,
      layerId,
      markData.marks.length,
      userId,
      markData.comments.length > 0,
      JSON.stringify(markData)
    )
    // update the vue layer with new data first if it's the current users markup layer
    if (currentMarkupLayer) {
      commit('updateRedactionLayer', redactionsLayerData)
    }

    const allLayers = helpers.allLayersRedactionsCount(
      state.documents[state.activeDocId].redaction_layers
    )
    if (allLayers !== state.redactionLayerCount) {
      dispatch('setRedactionsLayerCount', allLayers)
    } else if (
      (markData.comments.length === 0 && state.redactionComment) ||
      (markData.comments.length > 0 && !state.redactionComment)
    ) {
      dispatch('updateComment', redactionsLayerData)
    }
    dispatch('saveRedactionsLayerInDB', redactionsLayerData)
    dispatch('updateMarkupLayerUpdatedAtInState', updatedAtTimestamp)
  },

  updateRedactionLayerAfterDraftRedaction: ({ dispatch, state }, job) => {
    if (state.activeDocId === job.document_id) {
      dispatch('setActiveDocId', '')
    }
    API.getRedactionLayer(job.document_id).then((response) => {
      if (!isEmpty(response.data.redaction_layer)) {
        dispatch('updateRedactionLayer', response.data.redaction_layer)
      }
    })
  },

  saveRedactionsLayerInDB: ({ dispatch }, redactionsLayerData) => {
    API.updateRedactionLayer(redactionsLayerData)
      .then(() => {
        dispatch('setAutoSaveState', 'Saved')
      })
      .catch((error) => {
        dispatch('setAutoSaveState', '')
        console.log('redaction layer failed to update in postgres')
        appToasted().error('Draft redaction could not be updated.')
        NrSentry.captureError(
          new Error('Error saving redaction layer in database', {
            cause: error
          })
        )
      })
  },

  zipFiles: ({ dispatch, state }, { prettyId, docIds }) => {
    if (docIds.length === 0) {
      dispatch('setActiveModal', 'noDocs')
    } else {
      API.zipFiles({ prettyId, docIds }).then((response) => {
        const jobId = response.data.jobId
        if (!jobId) {
          appToasted().error('Zip files failed to start.')
        }
        helpers.setPendingJobInLocalStorage(
          `${state.currentUser.id}${state.prettyId}zipFiles`,
          {
            docIds: docIds,
            jobId: jobId,
            expiry: addDays(new Date(), 2),
            jobType: 'zipfile_creator'
          }
        )
      })
    }
  },

  updateMarkupLayerUpdatedAtInState: ({ commit }, val) => {
    commit('updateMarkupLayerUpdatedAtInState', val)
  },

  fetchZipFileToDownload: (_, payload) => {
    return API.fetchDocFiles(payload).then((response) => {
      const link = document.createElement('a')
      link.href = response.data.url
      link.setAttribute('download', response.data.filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      if (response.data.bulk) {
        helpers.removePendingJobFromLocalStorage(
          `${globalModule.state.currentUser.id}${payload.request_id}zipFiles`,
          payload.jid
        )
      }
    })
  },

  onJobComplete: ({ dispatch, state }, data) => {
    const jobTypes = [
      'zipfile_creator',
      'zipfile_reader',
      'mail_reader',
      'multiple_doc_burner',
      'process_folders',
      'delete_documents',
      'draft_redaction',
      'visibility_updater',
      'flatten',
      'redaction_log_service',
      'bulk_doc_download'
    ]
    const cookieName = data.cookieName
    const job = data.job
    const processedJob = data.processedJob
    if (processedJob && processedJob.docIds && processedJob.docIds.length) {
      dispatch('removeProcessingDocIds', processedJob.docIds)
      helpers.removePendingJobFromLocalStorage(cookieName, job.id)
      const extractionComplete =
        state.activeReviewState !== 'Processed' &&
        (job.job_type === 'zipfile_reader' || job.job_type === 'mail_reader')
      if (extractionComplete || job.job_type === 'multiple_doc_burner') {
        dispatch('removeDocsFromState', processedJob.docIds)
      }
      if (jobTypes.includes(job.job_type)) {
        dispatch('fetchFolders', state.prettyId)
        dispatch('refreshDocumentReviewList')
      }
      if (job.job_type === 'draft_redaction') {
        dispatch('updateRedactionLayerAfterDraftRedaction', job)
      }
    }
  },

  onJobFail: ({ dispatch, state }, data) => {
    const cookieName = data.cookieName
    const job = data.job
    const processedJob = data.processedJob
    if (processedJob && processedJob.docIds && processedJob.docIds.length) {
      dispatch('removeProcessingDocIds', processedJob.docIds)
      helpers.removePendingJobFromLocalStorage(cookieName, job.id)
      dispatch('fetchFolders', state.prettyId)
      dispatch('fetchReviewStateCounts', state.prettyId)
      dispatch('resetReviewState', state.activeReviewState)
    }
  },

  setFolderOptions: ({ commit }, data) => {
    commit('setFolderOptions', data)
  },

  setDocReviewStatus: ({ commit, state }, val) => {
    const params = {
      id: val.id,
      review_status: val.reviewStatus
    }
    updateDocReviewStatus(params)
      .then((response) => {
        const doc = state.documents[response.document_id]
        commit('setDocument', {
          ...doc,
          document_scan: {
            ...doc.document_scan,
            review_status: response.review_status
          }
        })
        appToasted().success('Successfully updated document review status')
      })
      .catch((error) => {
        appToasted().error('Error updating document review status')
        NrSentry.captureError(error)
      })
  },

  setDocuments: ({ commit }, documents) => commit('setDocuments', documents)
}
