import { debounce, memoize } from 'lodash'
import requestColumns from '../pages/requests/components/columns'

const Helpers = {
  allLayersRedactionsCount: (layers) => {
    let redactionCount = 0
    layers.forEach((rl) => {
      redactionCount += rl.layer_count
    })
    return redactionCount
  },

  dbParamsObj: (...args) => {
    return {
      document_id: args[0],
      layer_id: args[1],
      layer_count: args[2],
      user_id: args[3],
      comment: args[4],
      mark_data: args[5]
    }
  },

  baseMarkObject: (mark, comments) => {
    return {
      type: mark.type,
      uid: mark.getUid(),
      interactionMode: mark.getInteractionMode(),
      pageNumber: mark.getPageNumber(),
      data: mark.getData(),
      creationDateTime: new Date(),
      modificationDateTime: new Date(),
      conversation: {
        data: mark.getConversation().getData(),
        comments
      }
    }
  },

  lineGroupObject: (mark, pageData) => {
    return {
      pageNumber: mark.pageNumber,
      pageData,
      startIndex: mark.getPosition().startIndex,
      textLength: mark.getPosition().length,
      lines: [mark.getBoundingRectangle()]
    }
  },

  formattedMarkObject: (mark, comments, pageData) => {
    const markType = mark.getType()
    let markTypeObject = null

    switch (markType) {
      case PCCViewer.Mark.Type.RectangleRedaction: // eslint-disable-line no-undef
        markTypeObject = {
          rectangle: mark.getRectangle(),
          pageData,
          borderColor: mark.getBorderColor(),
          borderThickness: mark.getBorderThickness(),
          fillColor: mark.getFillColor(),
          fontColor: mark.getFontColor(),
          reason: mark.getReason()
        }
        break
      case PCCViewer.Mark.Type.TextSelectionRedaction: // eslint-disable-line no-undef
        markTypeObject = {
          lineGroups: [Helpers.lineGroupObject(mark, pageData)],
          startIndex: mark.getPosition().startIndex,
          textLength: mark.getPosition().length,
          reason: mark.getReason(),
          selectedText: mark.getText()
        }
        break
      default:
        return {}
    }
    return Object.assign(Helpers.baseMarkObject(mark, comments), markTypeObject)
  },

  buildUiElements(reviewState, redaction, rapidReview) {
    const redactionViewer =
      redaction === 'true' || (rapidReview === true && reviewState !== 'Ready')
    const uiOpts = {
      annotateTab: false,
      esignTab: false,
      fullScreenOnInit: false
    }
    if (redactionViewer) {
      uiOpts.advancedSearch = true
      uiOpts.attachments = !!rapidReview // This is only true for rapid review
      uiOpts.copyPaste = true
      uiOpts.download = true // This needs to be true if we are allowing for burn-in/Save
    } else {
      uiOpts.attachments = false
      uiOpts.copyPaste = false
      uiOpts.download = false
      uiOpts.printing = false
      uiOpts.redactTab = false
    }
    return uiOpts
  },

  backgroundJobFailed(job) {
    return (
      job === 'failed' ||
      job === 'interrupted' ||
      job === 'dead' ||
      job === undefined
    )
  },

  backgroundJobComplete(job) {
    return job === 'complete'
  },

  stopReportJobPolling(pollJob) {
    let pendingReports = localStorage.getItem('pendingReports')
    pendingReports = JSON.parse(pendingReports)
    if (pendingReports.length === 0) {
      clearInterval(pollJob)
    }
  },

  removeReportJobFromLocalStorage(jobId) {
    let pendingReports = localStorage.getItem('pendingReports')
    pendingReports = JSON.parse(pendingReports)
    pendingReports = pendingReports.filter((rep) => rep.job_id !== jobId)
    pendingReports = JSON.stringify(pendingReports)
    localStorage.setItem('pendingReports', pendingReports)
  },

  setReportJobInLocalStorage(jobInfo) {
    // pendingReports should be an array because there can be more than 1 pending report job for a user
    let pendingReports = localStorage.getItem('pendingReports')
    if (pendingReports) {
      pendingReports = JSON.parse(pendingReports)
      pendingReports.push(jobInfo)
      localStorage.setItem('pendingReports', JSON.stringify(pendingReports))
    } else {
      localStorage.setItem('pendingReports', JSON.stringify([jobInfo]))
    }
  },

  fetchReportInfoFromLocalStorage(jobId) {
    let pendingReports = localStorage.getItem('pendingReports')
    pendingReports = JSON.parse(pendingReports)
    let reportInfo = pendingReports.filter((rep) => rep.job_id === jobId)
    reportInfo = reportInfo[0]
    return {
      filePath: reportInfo.filepath,
      reportType: reportInfo.report_type
    }
  },

  forceFileDownload(data) {
    const blob = new Blob([data.reportData], {
      type: ''
    })
    if (window.navigator.msSaveOrOpenBlob) {
      // For IE 11
      window.navigator.msSaveOrOpenBlob(blob, data.fileName)
    } else {
      // For all other browsers (Chrome, Firefox, etc.)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', data.fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },

  requestColumnIsAuthorized(ability, account, col) {
    // Disable/Enable columns based on account modules and user permissions
    const staffOnly = ability.can('read', 'staff_only_items')
    const showDueDate = staffOnly || (!staffOnly && account.show_due_date)
    const showRequesterName = staffOnly || account.show_requester
    const showStaffCost =
      account.payments && (staffOnly || account.show_cost_publicly)
    const showExpirationDate =
      ability.can('read', 'admin_only_items') && account.retention
    return (
      (col.field === 'visibility' && !staffOnly) ||
      (col.field === 'due_date' && !showDueDate) ||
      (col.field === 'request_date' && !staffOnly) ||
      (col.field === 'requester_name' && !showRequesterName) ||
      (col.field === 'staff_cost' && !showStaffCost) ||
      (col.field === 'expiration_date' && !showExpirationDate)
    )
  },

  setSortableInColumns(columns) {
    if (!columns) return
    return columns.map((col) => {
      const defaultColumn = requestColumns.find(
        (defaultCol) => defaultCol.field === col.field
      )
      const isSortable = defaultColumn.sortable
      return {
        ...col,
        sortable: isSortable
      }
    })
  },

  selectAuthorizedRequestColumns(columns, globalModule, ability) {
    if (!columns) return
    return columns.filter(
      (col) =>
        !Helpers.requestColumnIsAuthorized(
          ability,
          globalModule.state.account,
          col
        )
    )
  },

  setupDocColumns(columns, account, ability) {
    const redactedAtColumn = columns.find((col) => col.field === 'redacted_at')
    const staffOnly = ability.can('read', 'staff_only_items')
    redactedAtColumn.hidden = !account.redaction || !staffOnly

    const visibilityColumn = columns.find((col) => col.field === 'state')
    visibilityColumn.hidden = !staffOnly
    return columns
  },

  attachTimelineIcons(timelineObj) {
    return Object.keys(timelineObj).map((item) => {
      const icon =
        timelineObj[item].timeline_icon_class &&
        timelineObj[item].timeline_icon_class.split(' ')[1].slice(3)
      const timelineState = timelineObj[item].timeline_state
      return {
        ...timelineObj[item],
        iconname: icon,
        color:
          timelineState === 'Requester + Staff' ||
          timelineState === 'Anyone with access to this request'
            ? 'green'
            : 'orange'
      }
    })
  },

  returnColumnsFromCookie(cookies, currentUser) {
    const columns = !currentUser
      ? cookies.get('publicTableColumns')?.columns
      : cookies.get(`user${currentUser.id}TableColumns`)?.columns
    return Helpers.setSortableInColumns(columns)
  },

  setPendingJobInLocalStorage(cookieName, newJob) {
    let pendingJobs = localStorage.getItem(cookieName)
    if (pendingJobs && pendingJobs.length) {
      pendingJobs = JSON.parse(pendingJobs)
      pendingJobs = [...[newJob], ...pendingJobs]
      localStorage.setItem(cookieName, JSON.stringify(pendingJobs))
    } else {
      pendingJobs = JSON.stringify([newJob])
      localStorage.setItem(cookieName, pendingJobs)
    }
  },

  getPendingJobsInLocalStorage(cookieName) {
    const pendingJobs = JSON.parse(localStorage.getItem(cookieName) || '[]')
    return pendingJobs.length ? pendingJobs : null
  },

  removePendingJobFromLocalStorage(cookieName, jobId) {
    let processingJobs = localStorage.getItem(cookieName)
    processingJobs = JSON.parse(processingJobs)
    processingJobs = processingJobs.filter((job) => {
      return job.jobId !== jobId
    })
    localStorage.setItem(cookieName, JSON.stringify(processingJobs))
  },

  fetchJobTypes(userId, prettyId, page) {
    let jobTypes = []
    if (page === 'rapid_review') {
      jobTypes = [
        `${userId}${prettyId}zipFiles`,
        `${userId}${prettyId}burnDraftRedaction`,
        `${userId}${prettyId}draftRedaction`,
        `${userId}${prettyId}email`,
        `${userId}${prettyId}zipFileReader`,
        `${userId}${prettyId}moveToFolder`,
        `${userId}${prettyId}bulkDelete`,
        `${userId}${prettyId}ocr`,
        `${userId}${prettyId}bulkFlatten`,
        `${userId}${prettyId}redactionLogService`
      ]
    }
    if (page === 'request_show') {
      jobTypes = [
        `${userId}${prettyId}requestShowChangeVisibility`,
        `${userId}${prettyId}requestShowDownload`,
        `${userId}${prettyId}requestShowDelete`,
        `${userId}${prettyId}requestShowMoveFolder`,
        `${userId}${prettyId}requestShowChangeExpiration`
      ]
    }
    return jobTypes
  },

  removePendingJobById(jobId, userId, prettyId, page) {
    const jobTypes = Helpers.fetchJobTypes(userId, prettyId, page)
    if (!jobTypes.length) return
    jobTypes.forEach((jobs) => {
      let pendingJobs = localStorage.getItem(jobs)
      if (pendingJobs && pendingJobs.length) {
        pendingJobs = JSON.parse(pendingJobs)
        const pendingJob = pendingJobs.find((job) => job.jobId === jobId)
        if (pendingJob) {
          Helpers.removePendingJobFromLocalStorage(jobs, pendingJob.jobId)
        }
      }
    })
  },

  getPendingJob(objectName, jobId) {
    let pendingJobs = localStorage.getItem(objectName)
    pendingJobs = JSON.parse(pendingJobs)
    if (pendingJobs && pendingJobs.length) {
      return pendingJobs.find((job) => {
        return job.jobId === jobId
      })
    }
    return null
  },

  fetchPendingJobById(jobId, userId, prettyId, page) {
    const jobTypes = Helpers.fetchJobTypes(userId, prettyId, page)
    let pendingJob = {}
    if (!jobTypes.length) return
    jobTypes.forEach((jobs) => {
      let shouldSkip = false
      if (shouldSkip) return
      let pendingJobs = localStorage.getItem(jobs)
      if (pendingJobs && pendingJobs.length) {
        pendingJobs = JSON.parse(pendingJobs)
        const job = pendingJobs.find((job) => jobId === job.jobId)
        if (job) {
          pendingJob = job
          shouldSkip = true
        }
      }
    })
    return pendingJob
  },

  fetchUserProcessingJobIds(userId, prettyId, page) {
    let processingJobIds = []
    const jobTypes = Helpers.fetchJobTypes(userId, prettyId, page)
    if (!jobTypes.length) return
    jobTypes.forEach((jobs) => {
      let pendingJobs = localStorage.getItem(jobs)
      if (pendingJobs && pendingJobs.length) {
        pendingJobs = JSON.parse(pendingJobs)
        const jobIds = pendingJobs.map((job) => job.jobId)
        processingJobIds = [...processingJobIds, ...jobIds]
      }
    })
    return processingJobIds
  },

  fetchAllProcessingDocIds(userId, prettyId, page) {
    let processingDocIds = []
    const jobTypes = Helpers.fetchJobTypes(userId, prettyId, page)
    if (!jobTypes.length) return
    jobTypes.forEach((jobs) => {
      let pendingJobs = localStorage.getItem(jobs)
      if (pendingJobs && pendingJobs.length) {
        pendingJobs = JSON.parse(pendingJobs)
        pendingJobs.forEach((job) => {
          if (new Date(job.expiry) < new Date()) {
            Helpers.removePendingJobFromLocalStorage(jobs, job.jobId)
          } else {
            processingDocIds = [...processingDocIds, ...job.docIds]
          }
        })
      }
    })

    return processingDocIds
  },

  // Memoizes debounce, so that one debounced function is created per cache key.
  // Note that the underlying `func` is not memoized.
  memoizeDebounce(func, resolver = null, wait = 0, debounce_options = {}) {
    const mem = memoize(function () {
      return debounce(func, wait, debounce_options)
    }, resolver)
    return function () {
      mem.apply(this, arguments).apply(this, arguments)
    }
  },

  setMyRequestsTooltipText(dataType, currentUser, filterOptions) {
    return dataType
      ?.map((id) =>
        id === currentUser?.id
          ? currentUser?.name_with_email
          : filterOptions.find((obj) => obj.id === id)?.name_or_email
      )
      ?.join(', ')
  },

  formatCost(cost, maximumFractionDigits = 2) {
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: maximumFractionDigits,
      style: 'currency',
      currency: 'USD'
    })
    return formatter.format(cost)
  }
}

export default Helpers
